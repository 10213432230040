<template>
  <div class="main">
    <div class="container">
      <div class="news_header">
        <div class="title">{{ detail.title }}</div>
        <div class="time">{{ detail.time }}</div>
      </div>
      <div class="news_detail">
        <template v-if="detail.img.length > 0">
          <img
            v-for="(item, index) in detail.img"
            :key="index"
            :src="$OSSURL + item"
          />
        </template>
        <div class="text" v-html="detail.desc"></div>
      </div>
      <div class="backBtn" @click="pageBack"></div>
    </div>
  </div>
</template>

<script>
import { DATA } from '@/data/a_updates.js';
export default {
  name: 'Index',
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      detail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.detail = DATA.find(item => {
        return item.id == this.id;
      });
      console.log(this.detail);
    },
    pageBack() {
      this.$router.replace({
        path: '/Awards/Updates',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
